@import '../utils/variables';

.head{
    font-family: 'Figtree', sans-serif;
    font-weight: 400;
    font-size: 26pt;
    b{
        font-weight: 700;
        color: $default-color;
    }

    @media (max-width : $big-screen){
        font-size: 22pt;
    }

    @media (max-width : $mobile-big){
        font-size: 20pt;
        // text-align: center;
    }

    // @media (max-width : $mobile-small){
    //     font-size: 18pt;
    // }
}

.title-1{
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 24pt;

    @media (max-width : $big-screen){
        font-size: 20pt;
    }

    // @media (max-width : $mobile-big){
    //     font-size: 18pt;
    // }

    // @media (max-width : $mobile-small){
    //     font-size: 12pt;
    // }
}

.title-2{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 22pt;
    color: $default-color;

    @media (max-width : $big-screen){
        font-size: 18pt;
    }

    // @media (max-width : $mobile-big){
    //     font-size: 16pt;
    // }

    // @media (max-width : $mobile-small){
    //     font-size: 12pt;
    // }
}

.title-3{
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 20pt;

    // @media (max-width : $big-screen){
    //     font-size: 20pt;
    // }

    @media (max-width : $mobile-big){
        font-size: 16pt;
    }

    // @media (max-width : $mobile-small){
    //     font-size: 12pt;
    // }
}

.headline{
    font-family: 'Figtree', sans-serif;
    font-weight: 400;
    color: #124658;
    font-size: 26px;

    // @media (max-width : $big-screen){
    //     font-size: 16pt;
    //     text-align: center;
    // }

    @media (max-width : $mobile-big){
        font-size: 14pt;
    }

    // @media (max-width : $mobile-small){
    //     font-size: 12pt;
    // }
}


.quote{
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14pt;

    @media (max-width : $big-screen){
        font-size: 12pt;
        text-align: center;
    }

    @media (max-width : $mobile-big){
        font-size: 10pt;
    }

    // @media (max-width : $mobile-small){
    //     font-size: 8pt;
    // }
}

.menu{
    font-family: 'Figtree', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: #4C5F66;

    @media (max-width : $mobile-big){
        font-size: 16px;
    }
}

.menulong{
    @media(min-width: $mobile-big){
        font-size: 18px;
    }
}