@import "../utils/variables";

// .apply-input{
//     font-family: "Montserrat", sans-serif;
//     font-weight: 400;
//     font-size: 36pt;

//     border-radius: 10px;
//     border-color: $hover-color;
//     border-style: solid;
//     outline: unset;

//     color: $disabled-color;

//     transition: border-width 300ms ease-in-out;

//     &:focus-visible{
//         border-color: $default-color;
//         border-width: 3px;
//         outline: unset;

//         color: #000000;

//         transition: border-width 300ms ease-in-out;
//     }
// }

.apply-input,
.apply-textarea,
.apply-select {
    font-family: 'Figtree', sans-serif;
    font-weight: 500;
    font-size: 20px;
    border: 0px;
    // box-shadow: $disabled-color 0px 1px;
    border-bottom: 1px solid $disabled-color !important;
    padding: 0px 0px 8px;
    transition: border 0.1s ease-out 0s;
    //border-bottom: 0.5px solid $disabled-color;
    color: #124658;

    &:focus-visible {
        color: #000000;
        outline: none;
        // box-shadow: $default-color 0px 1.5px;
        border-bottom: 1.5px solid $default-color !important;
        transition: border 0.1s ease-out 0s;
    }

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $disabled-color;
        opacity: 1; /* Firefox */
    }

    @media (max-width : $big-screen){
        font-size: 16pt;
    }

    @media(max-width : $mobile-big){
        font-size: 14pt;
    }
}
