@import "../utils/variables";

.apply-btn-default {
    user-select: none;
    min-width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: white;
    padding: 10px 20px;
    font-weight: 700;
    border: 0;
    background-color: #FFE8E6;

    path {
        fill: #FD5B4F;
    }

    &:hover {
        cursor: pointer;
        background-color: $hover-color;
    }

    &:disabled {
        cursor: not-allowed;
        // outline: 1px solid $disabled-color !important;
        //border: 1px solid $disabled-color !important;
        color: $disabled-color;
        pointer-events: none;
        background-color: #EBEFF0;

        path {
            fill: #B6C4C9;
        }
    }

    &:focus-visible {
        background-color: $click-color;
        // outline: 1px transparent;
        border: 1px transparent;
    }
}

// .apply-option--unselected{
//     color: $disabled-color;
//     background-color: white;
//     outline: 2px solid $disabled-color;
// }

.apply-option {
    border: 2px solid $disabled-color;
    border-radius: 100%;
    min-width: 32px;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $disabled-color;
    background-color: white;
    margin-left: 6px;

    &:hover {
        cursor: pointer;
        background-color: #EAF6FB;
        color: white;
        box-shadow: 0 0 0 6px #EAF6FB;
    }

    &:disabled {
        pointer-events: none;
        cursor: not-allowed;
    }

    // &:focus-visible{
    //     background-color: $click-color;
    //     outline: 2px transparent;
    //     color: white;
    // }
}

.apply-option--selected {
    color: white !important;
    background-color: #2796BF !important;
    border: 1px solid #2796BF !important;

    // animation-iteration-count: 1;
    // animation-name: select;
    // animation-duration: 300ms;
}

// @keyframes select {
//     from {
//         border: 1px solid $disabled-color;
//         background-color: $disabled-color;
//     }

//     to {
//         border: 1px solid $default-color;
//         background-color: $default-color;
//     }
// }

.da-btn {
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;

    width: fit-content;
    font-family: 'Figtree', sans-serif;

    background-color: #fd5b4f;
    color: white;
    border: 2px solid #fd5b4f;
    padding: 18px 58px;
    font-size: 20px;

    &:hover {
        color: var(white);
        background: linear-gradient(0deg, rgba(252, 45, 28, 0.5), rgba(252, 45, 28, 0.5)), #fd5b4f;
        border: 2px solid #FC4436;
    }

    &:disabled {
        cursor: not-allowed;
        background-color: #F0F3F4;
        border-color: #F0F3F4;
        color: $disabled-color;

        &:hover {
            background: #F0F3F4;
            border-color: #F0F3F4;
            color: #B6C4C9;
        }
    }

    @media(max-width: 1400px) {
        padding: 14px 48px;
    }

    @media(max-width: $mobile-small) {
        padding: 10px 40px;
    }
}

.da-btn-fake-disabled {
    background-color: #F0F3F4;
    border-color: #F0F3F4;
    color: $disabled-color;

    &:hover {
        background: #F0F3F4;
        border-color: #F0F3F4;
        color: #B6C4C9;
    }

}