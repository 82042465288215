@import "./styles/base/_typography";
@import "./styles/components/_buttons";
@import "./styles/components/_inputs";
@import "./styles/utils/_margins";
@tailwind base;
@tailwind components;
@tailwind utilities;
// @import "custom-form-lib/src/theme/tailwind.scss";
// @layer base {
//     @import "./styles/fonts/fonts.scss";
// }

html {
    scroll-behavior: smooth;
}

body {
    font-family: "Figtree", sans-serif;
    color: rgb(5, 5, 5);
    margin: 0;
    line-height: 1.42;
}

h2 {
    font-size: 22px;
    font-weight: 500;
    color: rgb(16, 16, 16);
    font-family: "Figtree", sans-serif;
}

.text-pink {
    color: #fd7065 !important;
}

.font-size-xl {
    font-size: 30px;
    margin-top: 4px;
}

.font-size-26 {
    font-size: 26px;
}

.font-size-large {
    font-size: 22px;
}

.font-size-larger {
    font-size: 38px !important;
}

.font-size-medium {
    font-size: 16px;
}

.apply-btn {
    background-color: #fd7065;
    color: white !important;
    font-size: 16px;
    box-shadow: inset 0 1px 1px 0 rgba(255, 255, 255, 0.22);
    border-radius: 6px;
    border-bottom: 4px solid rgba(0, 0, 0, 0.13);
    padding: 16px;
    padding-left: 32px;
    padding-right: 32px;
    text-decoration: none;
    font-weight: 700;

    &:hover {
        background-color: #fd8178;
        text-decoration: none !important;
    }
}

.mb-l {
    margin-bottom: 55px;
}

.mb-s {
    margin-bottom: 44px;
}

.mt-l {
    margin-top: 55px;
}

.mt-m {
    margin-top: 32px;
}

.mt-s {
    margin-top: 16px;
}

.pr-m {
    padding-right: 16px;
}

.pr-xl {
    padding-right: 80px;
}

.pl-xl {
    padding-left: 80px;
}

.pl-m {
    padding-left: 16px;
}

.letter-spacing-1 {
    letter-spacing: 1px;
}

.container {
    padding-left: 64px;
    padding-right: 64px;
    padding-bottom: 55px;
    padding-top: 55px;
}

.flex-center {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
}

.fade_rule {
    height: 1px;
    background-color: #e6e6e6;
    width: 80vw;
    margin: 0 auto;
    background-image: linear-gradient(to left, white 2%, #e6e6e6 50%, white 98%);
}

.table-cell-center {
    text-align: center;
}

.line-black {
    height: 3px;
    background-color: #0d0c0c;
    width: 150px;
}

.d-flex {
    display: flex;
}

.bg-dark {
    // padding-top: 24px;
    // padding-bottom: 24px;
    background-color: #4c535e;

    h2 {
        color: white;
        text-align: center;
        font-size: 28px;
    }
}

.bg-gray {
    background-color: #fffcfc;
}

.flex-root {
    display: flow-root;
}

.table {
    display: table;
}

.table-cell {
    display: table-cell;
    width: 50%;
    vertical-align: middle;

    h3 {
        font-size: 24px;
    }

    h3,
    p {
        text-align: center;
    }
}

.d-none {
    display: none;
}

.logo_name {
    max-height: 80px;
    object-fit: contain;

    @media (max-width: $mobile-small) {
        max-width: 150px;
    }
}

.tuev {
    max-width: 150px;
    max-height: 80px;
    object-fit: contain;
}

@media (min-width: 800px) {
    .width-33 {
        width: 33% !important;
    }

    .width-67 {
        width: 67% !important;
    }
}

.image-soft-round {
    width: 400px;
    border-radius: 5px;
}

.line-pink {
    width: 140px;
    height: 3px;
    background-color: #fd7065;
}

.text-left {
    text-align: start !important;
}

.text-center {
    text-align: center !important;
}

.v-align-top {
    vertical-align: top;
}

.v-align-bottom {
    vertical-align: bottom !important;
}

.round-corners {
    border-radius: 5px;
}

.hide-desktop {
    display: none !important;
}

.card {
    border-radius: 5px;
    box-shadow:
        rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
        rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    background-color: white;
    padding: 16px;

    h1 {
        color: #333 !important;
        font-size: 26px;
        margin-bottom: 0;
    }

    hr {
        margin-top: 0;
    }
}

.very-small-screen-only,
.small-screen-only,
.medium-screen-only,
.big-screen-only {
    display: none;
}

.certificate {
    width: 200px;
    height: 100px;
    object-fit: contain;
    object-position: 0;
}

@media (max-width: 450px) {
    .font-size-larger {
        font-size: 22px !important;
    }
}

@media (max-width: 500px) {
    .very-small-screen-only {
        display: unset !important;
    }
}

@media (min-width: 500px) and (max-width: 800px) {
    .small-screen-only {
        display: unset !important;
    }
}

@media (min-width: 800px) and (max-width: 1300px) {
    .medium-screen-only {
        display: unset !important;
    }
}

@media (min-width: 1300px) {
    .big-screen-only {
        display: unset !important;
    }
}

@media (min-width: 1200px) {
    .container {
        padding-left: 128px;
        padding-right: 128px;
    }
}

@media (min-width: 1400px) {
    .container {
        padding-left: 192px;
        padding-right: 192px;
    }
}

@media (min-width: 1600px) {
    .container {
        padding-left: 256px;
        padding-right: 256px;
    }
}

@media (min-width: 1800px) {
    .container {
        padding-left: 320px;
        padding-right: 320px;
    }
}

@media (max-width: 900px) {
    body {
        text-align: center;
    }

    .container {
        padding-left: 48px;
        padding-right: 48px;
        padding-bottom: 27px;
        padding-top: 27px;
    }

    .hide-desktop {
        display: unset !important;
    }

    .hide-mobile {
        display: none !important;
    }

    .table-cell {
        // display: table-row;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .mobile-reverse-column {
        display: flex;
        flex-direction: column-reverse;
    }

    .image-soft-round {
        width: 100%;
    }

    .mobile-mt-l {
        margin-top: 55px;
    }

    .mt-l {
        margin-top: 27px;
    }

    .mb-l {
        margin-bottom: 27px;
    }

    .pr-xl {
        padding-right: 0 !important;
    }

    .pl-xl {
        padding-left: 0 !important;
    }
}

@media (max-width: 350px) {
    .very-small-screen-only {
        width: calc(100vw - 20px);
    }
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Figtree", sans-serif;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #2796bf !important;
}

.mat-checkbox-layout {
    white-space: normal !important;
}

.mat-checkbox-inner-container {
    width: 32px !important;
    height: 32px !important;

    @media (max-width: 1400px) {
        width: 28px !important;
        height: 28px !important;
    }
}

.mat-checkbox-background,
.mat-checkbox-frame {
    border-radius: 10px !important;
}

.error .mat-checkbox-frame {
    border-color: red !important;
}

.error .mat-checkbox-checkmark {
    stroke: red !important;
}

.error .mat-checkbox-checkmark-path {
    stroke: red !important;
}
