$default-color : #fd7065;
$disabled-color : #b6c4c9;
$hover-color : #ffa49f;
$click-color : #f94a41;

$bg-gray : #e6e6e6;
$bg-dark : #192d35;

$big-screen : 1400px;

$mobile-big : 900px;
$mobile-small : 600px;

$mobile-smaller : 400px;
$mobile-padding : 20px;